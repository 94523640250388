import React from 'react';
import { Box } from '@chakra-ui/react';
import Carousel from '@itseasy21/react-elastic-carousel';
import { Card } from '../Cards/NFTCard';
import { useRouter } from 'next/router';

const breakPoints = [
	{ width: 1, itemsToShow: 2, showArrows: true },
	{ width: 550, itemsToShow: 3, showArrows: true },
	{ width: 850, itemsToShow: 4, showArrows: true },
];

const CarouselNFT: React.FC<any> = ({ nft, isCreator, cardWidth, isAvatar, isDollar, buttonTxt }) => {
	const router = useRouter();
	return (
		<Carousel isRTL={false} breakPoints={breakPoints} itemPadding={[0, 10]}>
			{nft.map((item: any) => (
				<Box h={'100%'} mx={0} pb={5} key={item?.id}>
					<Card
						isDollar={isDollar}
						isCreator={isCreator}
						isAvatar={isAvatar}
						item={Object.assign(item, {
							buttonTxt: buttonTxt ? buttonTxt : 'Buy NFT',
						})}
						tokenId={item.tokenId.toString()}
						callback={() => {
							router.push(`/nft/${item.tokenId}`);
						}}
						key={item?.tokenId}
						cardWidth={cardWidth}
					/>
				</Box>
			))}
		</Carousel>
	);
};

export default CarouselNFT;
