import type { NextPage } from 'next';
import Head from 'next/head';
import Page from '../src/containers/Home';

const Home: NextPage = () => {
	return (
		<>
			<Head>
				<title>The ARK - NFT Marketplace</title>
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
			</Head>
			<Page />
		</>
	);
};

export default Home;
