import React, { useEffect, useState } from 'react';
import { Box, Button, Center, Container, Flex, Heading, Spinner, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { Image, Spacer } from '@chakra-ui/react';
import useConfigContext from '../contexts/ConfigContext';
import { ConfigKey, ISiteConfig, Nullable } from '../store/types';
import CarouselCollection from '../components/Collection/CarouselCollection';
import { useArkContext } from '../contexts';
import CarouselNFT from '../components/NFT/CarouselNFT';
import { getNFTLandingPage } from '../store/selectors';
import { fetchLandingPage, getSiteConfigByKey } from '../store/actions';
import LandingPageBanner from '../assets/images/landing-page-background.png';

const Landing = () => {
	const { state: configState } = useConfigContext();
	const [banner, setBanner] = useState('');
	const [title, setTitle] = useState('Welcome to The Ark');
	const [description, setDescription] = useState('');
	const router = useRouter();

	const onCollectionRedirect = (e: any) => {
		e.preventDefault();
		router.push('/collection');
	};
	const {
		dispatch,
		state: { ark: arkState },
	} = useArkContext();
	const orders = getNFTLandingPage(arkState);

	useEffect(() => {
		let data: Nullable<ISiteConfig> = getSiteConfigByKey(configState?.siteConfig, ConfigKey.SITE_LANDING_BANNER);
		if (data) {
			setBanner(data.VALUE);
		}
		data = getSiteConfigByKey(configState?.siteConfig, ConfigKey.SITE_TITLE);
		if (data) {
			setTitle(data.VALUE);
		}
		data = getSiteConfigByKey(configState?.siteConfig, ConfigKey.SITE_DESC);
		if (data) {
			setDescription(data.VALUE);
		}
	}, [setBanner, setTitle, setDescription, configState]);

	useEffect(() => {
		const fetchData = () => {
			if (!arkState.landingPage.success) {
				fetchLandingPage(arkState, dispatch);
			}
		};
		fetchData();
		router.events.on('routeChangeComplete', fetchData);
		return () => {
			router.events.off('routeChangeComplete', fetchData);
		};
	}, [router.events, arkState.landingPage.success, dispatch]);

	return (
		<>
			<Container maxW="container.lg" mt={10}>
				<Flex>
					<Center w="400px">
						<Box maxW="39rem">
							<Heading mb={4} fontSize="6xl">
								{title}
							</Heading>
							<Text fontSize="md">{description}</Text>
							<Button
								variant="gradient-primary"
								onClick={() => router.push('/marketplace')}
								size="lg"
								height="48px"
								width="300px"
								colorScheme="green"
								mt="36px"
							>
								Explore
							</Button>
						</Box>
					</Center>
					<Box flex="2" display={{ base: 'none', md: 'initial' }}>
						<Image alt="Landing Page Banner" src={banner} fallbackSrc={LandingPageBanner.src} />
					</Box>
				</Flex>

				{/******************** COLLECTION SECTION ************************/}

				<Flex mt={20} mb={5}>
					<Flex alignItems={'center'}>
						<Heading fontSize={20}>Trending Collections</Heading>
					</Flex>
					<Spacer />
					<Box>
						<Button onClick={onCollectionRedirect} bgGradient="linear(to-r, gdl, gdr)" size="sm">
							View All
						</Button>
					</Box>
				</Flex>
				<Flex className="CarouselCollection">
					{arkState.landingPage.loading ? (
						<Spinner mt={5} size="sm" />
					) : arkState.landingPage.collections.length > 0 ? (
						<CarouselCollection
							collection={arkState.landingPage.collections}
							isCreator={false}
							descriptionLength={40}
							isDescription={true}
							isAvatar={true}
						/>
					) : (
						<>
							<Center>No Trending Collections Found</Center>
						</>
					)}
				</Flex>

				{/******************** BROWSE MARKETPLACE SECTION ************************/}

				<Flex mt={20} mb={5}>
					<Flex alignItems={'center'}>
						<Heading fontSize={20}>Recently Listed NFTs</Heading>
					</Flex>
					<Spacer />
					<Box>
						<Button onClick={() => router.push('/marketplace')} bgGradient="linear(to-r, gdl, gdr)" size="sm">
							View All
						</Button>
					</Box>
				</Flex>
				<Flex className="CarouselCollection">
					{arkState.landingPage.loading ? (
						<Spinner mt={5} size="sm" />
					) : arkState.landingPage.orders.length > 0 ? (
						<CarouselNFT nft={orders} isDollar={false} isCreator={true} isAvatar={false} buttonTxt={'View NFT'} />
					) : (
						<>
							<Center>No NFTs Found</Center>
						</>
					)}
				</Flex>

				{/******************** BROWSE COLLECTIONS SECTION ************************/}
				<Flex mt={20} mb={5}>
					<Flex alignItems={'center'}>
						<Heading fontSize={20}>Recent Collections</Heading>
					</Flex>
					<Spacer />
					<Box>
						<Button onClick={onCollectionRedirect} bgGradient="linear(to-r, gdl, gdr)" size="sm">
							View All
						</Button>
					</Box>
				</Flex>
				<Flex className="CarouselCollection">
					{arkState.landingPage.loading ? (
						<Spinner mt={5} size="sm" />
					) : arkState.landingPage.newCollections.length > 0 ? (
						<CarouselCollection
							collection={arkState.landingPage.newCollections}
							isCreator={false}
							descriptionLength={40}
							isDescription={true}
							isAvatar={true}
						/>
					) : (
						<>
							<Center>No Recent Collections Found</Center>
						</>
					)}
				</Flex>
			</Container>
		</>
	);
};

export default Landing;
